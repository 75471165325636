import React from "react";
import back2 from "../../img/5.jpg";

//main skills
import reactLogo from "../../img/react.png";
import ts from "../../img/ts.png";
import reduxLogo from "../../img/reduxlogo.png";
import js from "../../img/js.png";
import html from "../../img/html.png";
import css from "../../img/css.png";
import mongo from "../../img/mongo.png";
import rest from "../../img/rest.png";
import node from "../../img/node.png";

// additional skills
import tdd from "../../img/tdd.png";
import mocha from "../../img/mocha.png";
import chai from "../../img/chai.png";
import sinon from "../../img/sinon.png";
import rabbit from "../../img/rabbit.png";
import git from "../../img/git.png";
import graphql from "../../img/graphql.png";
import apollo from "../../img/apollo.png";
import ph from "../../img/ph.png";

//languages
import eng from "../../img/eng.png";
import spain from "../../img/spain.png";
import ger from "../../img/germany.png";
import rus from "../../img/russia.png";

const Skills = () => {
  return (
    <>
      <img className="back" src={back2} alt="back2" />
      <div className="content skills">
        <div className="bot">
          <div className="line">
            <h3>Main skills:</h3>
            <div className="tech">
              <img
                src={reactLogo}
                alt="react logo"
                width="85"
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
              <img
                src={ts}
                alt="ts logo"
                width="85"
                style={{
                  borderRadius: "5px",
                }}
              />
              <img
                src={reduxLogo}
                alt="redux logo"
                width="85"
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
              <img
                src={js}
                alt="js logo"
                width="85"
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
              <img
                src={html}
                alt="html logo"
                width="85"
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
              <img
                src={css}
                alt="css logo"
                width="85"
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
              <img
                src={mongo}
                alt="mongo logo"
                width="85"
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
              <img
                src={node}
                alt="node logo"
                width="85"
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
              <img src={rest} alt="rest logo" width="85" />

              {/* <img src={gatsby} alt="gatsby logo" width="85" /> */}
              {/* <img src={less} alt="less logo" width="85" /> */}
              {/* <img src={webpack} alt="webpack logo" width="85" /> */}
              {/* <img src={babel} alt="babel logo" width="85" /> */}
              {/* <img src={chai} alt="chai logo" width="85" /> */}
              {/* <img src={mocha} alt="mocha logo" width="85" /> */}
            </div>
            <h3>Additional skills:</h3>
            <div className="tech">
              <img
                src={tdd}
                alt="tdd logo"
                width="85"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "white",
                }}
              />
              <img
                src={mocha}
                alt="mocha logo"
                width="85"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "white",
                }}
              />
              <img
                src={chai}
                alt="chai logo"
                width="85"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "white",
                }}
              />
              <img
                src={sinon}
                alt="sinon logo"
                width="85"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "white",
                }}
              />
              <img
                src={rabbit}
                alt="rabbit logo"
                width="85"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "white",
                }}
              />
              <img
                src={git}
                alt="git logo"
                width="85"
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
              <img
                src={graphql}
                alt="graphql logo"
                width="85"
                style={{
                  borderRadius: "5px",
                }}
              />
              <img
                src={apollo}
                alt="apollo logo"
                width="85"
                style={{
                  borderRadius: "5px",
                }}
              />
              <img
                src={ph}
                alt="photoshop logo"
                width="85"
                style={{
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>

          <div className="line">
            <h3 style={{ marginTop: "10px" }}>
              Ranked in Top-400 on{" "}
              <a
                href="https://www.codewars.com/users/leaderboard"
                target="_blank"
                rel="noopener noreferrer"
              >
                codewars.com with JavaScript
              </a>
            </h3>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.codewars.com/users/Konstantin%20Modin"
              >
                <img
                  className="cw"
                  src="https://www.codewars.com/users/Konstantin%20Modin/badges/large"
                  alt="codewars badge large"
                  // className="lowTransform"
                />
              </a>
            </div>
          </div>

          <div className="line">
            <div className="tech">
              <h3 style={{ marginTop: "10px" }}>Stack Overflow Links:</h3>
              <a
                href="https://stackoverflow.com/users/11124305/konstantin-modin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://stackoverflow.com/users/flair/11124305.png?theme=dark"
                  width="208"
                  height="58"
                  alt="profile for Konstantin Modin at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
                  title="Profile for Konstantin Modin at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
                  style={{ margin: "5px" }}
                  className="lowTransform stack"
                />
              </a>
              <a
                href="https://ru.stackoverflow.com/users/376593/konstantin-modin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://ru.stackoverflow.com/users/flair/376593.png?theme=dark"
                  width="208"
                  height="58"
                  alt="Профиль участника Konstantin Modin на сайте &#171;Stack Overflow на русском&#187;, Вопросы и ответы для программистов"
                  title="Профиль участника Konstantin Modin на сайте &#171;Stack Overflow на русском&#187;, Вопросы и ответы для программистов"
                  style={{ margin: "5px" }}
                  className="lowTransform stack"
                />
              </a>
            </div>
          </div>

          <div className="line">
            <h3 style={{ marginTop: "20px" }}>Languages</h3>
            <div className="lang">
              <div className="line">
                <img src={eng} alt="eng logo" width="85" />
                <h6>
                  Full <br />
                  Professional
                </h6>
              </div>
              <div className="line">
                <img src={spain} alt="spain logo" width="85" />
                <h6>
                  Full <br />
                  Professional
                </h6>
              </div>
              <div className="line">
                <img src={ger} alt="ger logo" width="85" />
                <h6>Elementary</h6>
              </div>
              <div className="line">
                <img src={rus} alt="rus logo" width="85" />
                <h6>Native</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
